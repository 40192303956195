<!--  -->
<template>
  <div class='consult-list'>
    <div class="page-way">
      <span @click="$router.push('Home')">首页</span>
      <div> > 常见问题</div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="handleSkip(index)">
        <h3>{{index+1}}.{{item.title}}</h3>
        <div>
          <p>{{item.describe}}</p>
          <div>{{item.add_time}}</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-pagination
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        layout="prev, pager, next, sizes"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="limit"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Api from "../../api/my"

export default {
  components: {},
  data() {
    return {
      list: [],
      page: 1,
      limit: 5,
      total: 32,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getConsult()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getConsult () {
      Api.getConsult({
        page: this.page,
        limit: this.limit,
        status: '常见问题'
      }).then(res => {
        if (res.data.code == 1) {
          this.total = Number(res.data.count)
          this.list = res.data.data
          this.list.forEach(item => {
            item.describe = item.describe ? item.describe.slice(0, 30) + '...' : item.describe
            item.years = item.add_time.split(" ")[0].split("-")
          })
        } else {
          this.$message.error("常见问题获取失败")
        }
        if (this.list.length == 0) {
          this.$emit('hide')
        }
      })
    },
    sizeChange (size) {
      this.limit = size
      this.getConsult()
    },
    currentChange (page) {
      this.page = page
      this.getConsult()
    },
    handleSkip (index) {
      let id = this.list[index].id
      this.$router.push({name: 'IssueInfo', query: {id: id}})
    }
  },
}
</script>

<style lang='less' scoped>
.consult-list {
  background-color: #fff;
  padding: 20px 40px;
  .page-way {
    margin: 0 0 15px;
    display: flex;
    color: #888;
    span {
      color: #409EFF;
      cursor: pointer;
      margin-right: 5px;
    }
  }
  .list {
    max-height: 400px;
    overflow-y: auto;
    .item {
      margin: 20px 0;
      border-bottom: 1px solid #e3e3e3;
      padding: 0 0 20px;
      cursor: pointer;
      h3 {
        margin-bottom: 30px;
      }
      > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        p {
          flex: 1;
          color: #333333;
        }
        div {
          margin-left: 30px;
          font-size: 14px;
        }
      }
    }
  }
  .footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    > div {
      width: fit-content;
    }
  }
}
</style>